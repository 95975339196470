.table-hello{
    height:calc(100vh - 300px);  
    overflow:scroll;
    border-collapse: collapse;
  }
.table-hello thead tr:nth-child(1) th{
    /* background: rgb(185, 166, 166); */
    position: sticky;
    top: 0;  
    z-index: 10;
}

.table-hello::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.table-hello::-webkit-scrollbar
{
	width: 10px;
  height:10px;
	background-color: #F5F5F5;
}

/* .table-responsive::-webkit-scrollbar:horizontal {
  height: 10px;
} */

.table-hello::-webkit-scrollbar-thumb
{
	background-color: #bebebe;
	/* background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent)); */
}



.cell-breakword{
  word-wrap: break-word;
  
}

.table-coll{
  width:100%;
  /* border-collapse: collapse;
  table-layout: fixed; */
}

.comment-td{
  max-width: 1px;
}

.comment-td-detail{
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
}

.td-w-30{
  width: 30%;
}

.td-w-min200{
  max-width:200px;
}

.schedule-td{
  padding: 0.75rem 0;
}