.title-color1{
    color: #f96300;
}

.title-color2{
    color:#73d7ff;
}

.comment-box{
    max-width:200px;
    word-wrap:break-word;
    overflow-wrap: break-word;
    white-space: nowrap;
}
