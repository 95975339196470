.nextprev_btn{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
}

.delete_btn{
    width:24px;
    height:24px;
    border-radius: 50%; 
    justify-content:center;
    display:flex;
}

.default_btn{
    width:24px;
    height:24px;
    justify-content:center;
    display:flex;
    border-radius: 50%; 
}


.delete_btn:hover { 
    background-color:#F1C59E; 
}

.picture_btn{
    width:18px;
    height:18px;
    border-radius:50%;
    background-color:#F1C59E; 
    justify-content:center;
    align-items: center;
    display:flex;
    top: 0;
    right:0;
    position: absolute;
}

.primary_btn{
    width:24px;
    height:24px;
    border-radius: 50%; 
    justify-content:center;
    display:flex;
}

.primary_btn:hover{
    background-color: #c3e7fd;
}

.success_btn{
    width:24px;
    height:24px;
    border-radius: 50%; 
    justify-content:center;
    display:flex;
}

.success_btn:hover{
    background-color: #d9fdd2;
}


.create_btn{
    font-size: 3rem;
}

.create_btn_parent{
    position: fixed;
    bottom: 10px;
    right:20px;
}

.setting_btn{
    width:24px;
    height:20px;
}

.min_width_50_btn{
    min-width:50px;
}