@-moz-keyframes timer-loader {
    0% {
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes timer-loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes timer-loader {
    0% {
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* :not(:required) hides this rule from IE9 and below */
  .timer-loader:not(:required) {
    border: 6px solid #009387;
    -moz-border-radius: 24px;
    -webkit-border-radius: 24px;
    border-radius: 24px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-indent: -9999px;
    width: 48px;
    height: 48px;
  }
  .timer-loader:not(:required)::before {
    -moz-animation: timer-loader 1250ms infinite linear;
    -webkit-animation: timer-loader 1250ms infinite linear;
    animation: timer-loader 1250ms infinite linear;
    -moz-transform-origin: 3px 3px;
    -ms-transform-origin: 3px 3px;
    -webkit-transform-origin: 3px 3px;
    transform-origin: 3px 3px;
    background: #009387;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 19.2px;
    left: 15px;
    top: 15px;
  }
  .timer-loader:not(:required)::after {
    -moz-animation: timer-loader 15000ms infinite linear;
    -webkit-animation: timer-loader 15000ms infinite linear;
    animation: timer-loader 15000ms infinite linear;
    -moz-transform-origin: 3px 3px;
    -ms-transform-origin: 3px 3px;
    -webkit-transform-origin: 3px 3px;
    transform-origin: 3px 3px;
    background: #009387;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 16px;
    left: 15px;
    top: 15px;
  }

.overlay1 {
  background: rgba(0,0,0,0.3);
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: .80;
}