.image-gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    
}
.image-item {
    width: 10rem;
    height: 10rem;
    background: #fff;
    overflow: hidden;
    cursor: pointer;
    border: 1rem solid #fff;
    box-shadow: 0 0 .5rem rgba(0,0,0,.4);
}
.image-inner {
    width: 100%;
    height: 100%;
    background-size: cover!important;
    background-position: center!important;
    transition: all .3s ease;
    transform: scale(1);
    
}