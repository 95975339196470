@media (max-width: 991.98px){
    /* .scrollable-menu{
		height:'auto';
		max-height: 300px;
		overflow-x: hidden;
	} */

	.scrollable-nav{
		height:'auto';
		max-height: 100vh;
		overflow-x: hidden;
	}
}

.font-text{
	font-size: 1rem;
}

.font-small{
	font-size: 0.75rem;
}

.navbar-img{
	width: 75px;
	height: 60px;
}

.navbar-user-img{
	width:75px;
	height:75px;
}