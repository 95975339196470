.body-height {
    padding-top: 68px;
    height: 100vh;
}

.body-nav{
    min-height:68px;
}

.body-tabpane{
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - 108px);
    background-color:'#e8e9eb';
}

.body-tabpane1{
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - 107px);
    background-color:'#e8e9eb';
}

.body-tabpane-officer{
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - 143px);
    background-color:'#e8e9eb';
}

.body-tabpane1-officer{
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - 107px);
    background-color:'#e8e9eb';
}

.emp_filter_body{
    min-height: calc(100vh - 145px);
}

.emp_filter_body1{
    min-height: calc(100vh - 109px);
}

.report_page_space{
    padding-top: 0;
}

.function_body{
    padding-top:0;
}

.function_title{
    display: flex;
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .body-tabpane{
        overflow: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 143px);
        background-color:'#e8e9eb';
    }

    .body-tabpane1{
        overflow: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 107px);
        background-color:'#e8e9eb';
    }

    .body-tabpane-officer{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 143px);
        background-color:'#e8e9eb';
    }
    
    .body-tabpane1-officer{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 107px);
        background-color:'#e8e9eb';
    }

    .body-nav{
        height:68
    }
}

@media screen and (min-width: 992px) and (max-width: 1224px) {
    
    .body-tabpane{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 143px);
        background-color:'#e8e9eb';
    }

    .body-tabpane1{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 107px);
        background-color:'#e8e9eb';
    }

    .body-tabpane-officer{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 143px);
        background-color:'#e8e9eb';
    }
    
    .body-tabpane1-officer{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 107px);
        background-color:'#e8e9eb';
    }

    .body-nav{
        height: 68px;
    }
}

@media screen and (min-width: 1225px ){

    .body-tabpane{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 143px);
        background-color:'#e8e9eb';
    }

    .body-tabpane1{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 107px);
        background-color:'#e8e9eb';
    }

    .body-tabpane-officer{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 143px);
        background-color:'#e8e9eb';
    }
    
    .body-tabpane1-officer{
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 107px);
        background-color:'#e8e9eb';
    }

    .body-nav{
        height: 68px;
    }
}

.cursor-pointer{
    cursor: pointer;
}


.bor-right{
    border-right: 2px inset;
}

.bor-bottom{
    border-bottom: 1px solid;
}

.bor-all{
    border:1px solid;
}

hr.new1 {
    border-top: 1px solid black;
}

.profile_effect1{
    -webkit-box-shadow: 2px 10px 6px 2px #777;
    -moz-box-shadow: 2px 10px 6px 2px #777;
    box-shadow: 2px 10px 6px 2px #777;
    
}

.table-w20{
    width:20%
}

.table-w15{
    width: 15%;
}

.table-w30{
    width: 30%;
}

.table-w10{
    width: 10%;
}

.table-w5{
    width: 5%;
}



/* style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 150px)'}} */