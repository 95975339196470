.title_tab{
    padding:28px 0 0 0;
}


.function_icon{
    font-size: 0.75rem;
}

.information_group{
    padding: 0px;
}

.bg_title{
    background-color: #dee6f1;
}

.bg_emp_list{
    background-color: #dee6f1;
}

.profile_border_left{
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #e9ecef;
}

.information_table{
    min-height: calc(100vh - 316px);
}

.title_padding{
    padding-top: 1rem ;
}

.searchbar1{
    padding-top: 0;
}

@media (max-width: 1124px) {
    .profile_border_left{
        border-left: none;
    }
}

@media (max-width: 991.98px){
    .information_group{
        display: none;
    }

    .bg_title{
        background-color: transparent;
    }

    .information_table{
        min-height:0;
    }

    .title_padding{
        padding-top: 0;
        padding-left: 1rem * 0.25;
    }

    .searchbar1{
        padding-top: 1rem * 0.5;
    }
}

.information{
    padding: 5px 10px;
}

.cursor-pointer{
    cursor: pointer;
}

.emp-tabcontent-title{
    background-color: #dee6f1;
    height:38px;
}

.employee_list_nav{
    position: sticky;
    top:10px;
    
}