#overlay1 {
    background: rgba(0,0,0,0.3);
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
  }
  .spinner1 {
      margin: 0 auto;
      height: 50px;
      width: 50px;
      animation: rotate 0.8s infinite linear;
      border: 5px solid firebrick;
      border-right-color: transparent;
      border-radius: 50%;
      display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        margin: -25px 0 0 -25px;
  }
  @keyframes rotate {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }

  #dots1 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
}

#dots1 span {
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  -webkit-animation: dots1 1s infinite ease-in-out;
          animation: dots1 1s infinite ease-in-out;
}

#dots1 span:nth-child(1) {
  left: 0px;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

#dots1 span:nth-child(2) {
  left: 15px;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

#dots1 span:nth-child(3) {
  left: 30px;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

#dots1 span:nth-child(4) {
  left: 45px;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@keyframes dots1 {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    background: #000000;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
}
@-webkit-keyframes dots1 {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    background: #000000;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
}